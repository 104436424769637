var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"mb-5"},[_c('CCol',{attrs:{"lg":"4","xl":"3"}},[_c('TAside',{staticClass:"card"},[_c('CListGroup',[_c('CListGroupItem',[_c('CRow',[_c('CCol',{attrs:{"col":"12","lg":"12","xl":"6"}},[_c('TMessage',{staticClass:"mb-2",attrs:{"content":"From date","bold":""}}),_c('TInputDateTime',{key:_vm.startDateKey,staticClass:"mb-3",attrs:{"value":_vm.dateRange.start,"timeline":"start","placeholder":"Start date"},on:{"update:value":function($event){return _vm.$set(_vm.dateRange, "start", $event)}}})],1),_c('CCol',{attrs:{"col":"12","lg":"12","xl":"6"}},[_c('TMessage',{staticClass:"mb-2",attrs:{"content":"To date","bold":""}}),_c('TInputDateTime',{key:_vm.endDateKey,staticClass:"mb-3",attrs:{"value":_vm.dateRange.end,"timeline":"end","placeholder":"End date"},on:{"update:value":function($event){return _vm.$set(_vm.dateRange, "end", $event)}}})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12"}},[_c('SSelectStatus',{attrs:{"value":_vm.status,"label":"Status","options":[
                  {
                    id: '',
                    name: this.$t('All'),
                  },
                  {
                    id: 'not_payment_due_date_yet',
                    name: this.$t('Not payment due date yet'),
                  },
                  {
                    id: 'overdue_payment',
                    name: this.$t('Payment overdue'),
                  },
                  {
                    id: 'payment_due_today',
                    name: this.$t('Payment due date is today'),
                  },
                  {
                    id: 'payment_due_date_empty',
                    name: this.$t('No payment due date'),
                  },
                  {
                    id: 'outstanding',
                    name: this.$t('Payment not completed'),
                  } ]},on:{"update:value":function($event){_vm.status=$event}}})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12"}},[_c('SSelectSupplier',{attrs:{"label":"Supplier","value":_vm.filter.supplier_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "supplier_id", $event)}}})],1),_c('CCol',{staticClass:"mb-3",attrs:{"col":"12"}},[_c('SSelectCustomer',{attrs:{"label":"Buyer","value":_vm.filter.buyer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "buyer_id", $event)}}})],1)],1),_c('CRow',[_c('CCol',{staticClass:"d-flex mt-2",attrs:{"col":"12"}},[_c('TButton',{staticClass:"w-100",attrs:{"content":"Filter","disabled":_vm.loading || !_vm.dateRange.end},on:{"click":_vm.onFilter}}),_c('TButtonClear',{staticClass:"ml-1",attrs:{"variant":"outline","size":""},on:{"click":_vm.clearFilter}})],1)],1)],1),_vm._l((_vm.list),function(supplier,index){return _c('CListGroupItem',{key:("" + index),attrs:{"color":_vm.selected.supplier_id_selected == supplier.supplier_id
              ? 'primary'
              : 'secondary',"to":!_vm.loading
              ? _vm._.getAppendRouteQuery(_vm.$route, {
                  supplier_id_selected: supplier.supplier_id,
                })
              : ''}},[_c('div',{staticClass:"d-flex"},[_c('TMessage',{attrs:{"content":supplier.supplier
                  ? supplier.supplier.name
                  : supplier.supplier_id,"color":"primary","noTranslate":"","truncate":2}}),_c('TMessageMoney',{staticClass:"ml-auto",attrs:{"amount":supplier.outstanding,"currency":"JPY"}})],1)])}),(_vm.loading)?_c('CListGroupItem',[_c('TSpinner',{attrs:{"loading":_vm.loading}})],1):_vm._e(),(!_vm.list.length && !_vm.loading)?_c('CListGroupItem',[_c('TMessageNotFound')],1):_vm._e(),(!_vm.loading && _vm.list.length)?_c('SButtonLoadmore',{staticClass:"mt-2 mb-3",attrs:{"store":"order.statistics_payment_due_date_suppliers"}}):_vm._e()],2)],1)],1),(_vm.$route.query.supplier_id_selected)?_c('CCol',{attrs:{"lg":"8","xl":"9"}},[_c('div',{staticClass:"card p-3"},[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('TMessage',{attrs:{"content":"Supplier","bold":"","color":"primary"}}),(!_vm.lodash.isEmpty(_vm.detail))?_c('TMessage',{staticClass:"mb-2 h4",attrs:{"content":_vm.detail.supplier ? _vm.detail.supplier.name : _vm.detail.supplier_id,"noTranslate":"","bold":""}}):_vm._e()],1),_c('CCol',{attrs:{"md":"6"}},[_c('TMessage',{attrs:{"content":"Outstanding","bold":"","color":"primary"}}),_c('TMessageMoney',{staticClass:"h4",attrs:{"amount":_vm.detail.outstanding,"currency":"JPY"}})],1)],1)],1),(_vm.fetchFirstTime)?_c('TTableAdvance',{attrs:{"items":_vm.tableList,"fields":_vm.fields,"store":"order.purchase_payment","noFilter":"","resource":""},on:{"click-clear-filter":_vm.clearFilter},scopedSlots:_vm._u([{key:"supplier",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('TMessage',{staticClass:"text-nowrap",attrs:{"content":item.supplier.name,"noTranslate":""}})],1)]}},{key:"id",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"content":item.id,"to":_vm.lodash.getReferenceLink('purchase', item.id)}})],1)]}},{key:"outstanding",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.outstanding,"currency":item.currency_id}})],1)]}},{key:"buyer_id",fn:function(ref){
                  var item = ref.item;
return [_c('td',[(item.buyer)?_c('TLink',{staticClass:"text-truncate-0",attrs:{"content":item.buyer.name,"to":_vm.lodash.getReferenceLink('user', item.buyer_id)}}):_vm._e()],1)]}},{key:"items",fn:function(ref){
                  var item = ref.item;
return [_c('td',_vm._l((item.items),function(it){return _c('div',{key:it.id,staticClass:"d-flex"},[_c('TMessage',{attrs:{"bold":"","content":it.product_id,"noTranslate":""}}),(it.payment_due_date)?_c('span',{staticClass:"mx-1"},[_vm._v("-")]):_vm._e(),(it.payment_due_date)?_c('TMessageDateTime',{attrs:{"content":it.payment_due_date,"dateOnly":""}}):_vm._e()],1)}),0)]}},{key:"balance",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('div',{staticClass:"d-flex"},[_c('TMessageMoney',{attrs:{"amount":item.paid,"currency":item.currency_id,"color":"muted","noBold":""}}),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id || 'JPY',"color":"muted"}})],1)])]}}],null,false,443238925)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }